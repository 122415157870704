.Graph {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: rgb(251, 251, 251);
}

.GraphButton {
	height: fit-content;
}
