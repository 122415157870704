.SearchVis {
	font-size: 2vw;
	margin: 2%;
	display: none;
	width: 70vw;
	height: fit-content;
	background-color: #33373f;
	flex-direction: column;
	align-items: center;
}

#SearchAlgoVis0 {
	display: flex;
}
.SearchAlgoVisButton {
	font-size: 60%;
}

.SearchVisNumberParent {
	display: flex;
	justify-content: center;
	transition: all 0.5s ease;
	padding-top: 5%;
	width: fit-content;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	font-size: 1.5vw;
}

.SearchVisNumber {
	width: fit-content;
	transition-duration: 50ms;
	font-size: 70%;
	border-radius: 5%;
	border-style: none;
	border-width: 0.1px;
	background-color: #1f2226;
	padding-right: 0.1%;
	padding-left: 0.1%;
}

.SearchVisNumberOut {
	transform: translateY(-30px);
	width: 0px;
	border-width: 0px;
	border-color: transparent transparent;
	font-size: 0px;
	padding: 0%;
}

.SearchVisInfo {
	margin-top: 3%;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.SearchVisInfoHalf {
	text-align: left;
	font-size: 55%;
	width: 50%;
	padding: 1%;
}

.SearchVisCode {
	font-size: 50%;
	background-color: #18191b00;
	width: 50%;
	height: 92%;
	margin-right: 1%;
}

.SearchAlgoVisButton {
	background-color: #3c4049;
	border: none;
	color: #fff;
	padding: 5px 20px;
	font-size: 60%;
	cursor: pointer;
	border-radius: 4px;
	transition: background-color 0.3s ease, transform 0.3s ease;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	margin: 0.5%;
}

.SearchAlgoVisButton:hover {
	background-color: #595d67;
	transform: scale(1.1);
}

.SearchAlgoVisButton:active {
	background-color: #6d717a;
	transform: scale(0.95);
}
.SearchAlgoVisButton:disabled {
	background-color: #6c6c6c;
	cursor: not-allowed;
	opacity: 0.7;
	box-shadow: none;
}

.SearchAlgoVisButton:disabled:hover {
	transform: none;
}

@media (max-width: 500px) {
	.SearchVis {
		width: 90%;
		height: fit-content;
		margin-bottom: 10%;
	}
	.SearchVisTitle {
		font-size: 125%;
	}
	.SearchVisNumberParent {
		font-size: 120%;
	}
	.SearchVisNumber {
		padding: 1%;
		border-style: none;
		border-width: 0.1px;
	}
	.SearchVisCode {
		font-size: 100%;
	}
	.SearchVisInfoHalf {
		font-size: 100%;
	}
}
