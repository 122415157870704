button:hover {
	cursor: pointer;
}

.Main {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: fit-content;
	min-height: 100vh;
	background-color: #282c34;
	color: white;
	align-items: center;

	justify-content: center;
	grid-template-columns: 40vw 40vw;
}

.VisualizerTile {
	margin: 1%;
	padding: 2%;
	font-size: 100%;
	transition-duration: 500ms;
	width: 50%;
	height: fit-content;
	background-color: #33373f;
}

.VisualizerTile:hover {
	font-size: 100%;
	width: 54%;
	background-color: #252525;
	cursor: pointer;
}
.VisualizerTile:active {
	background-color: #4e545e;
}

.VisualizerTileTitle {
	margin-left: 1%;
	font-size: 2vw;
	float: top;
	text-align: left;
	width: fit-content;
	height: fit-content;
}

.VisualizerTileImg {
	float: right;
	margin-top: -5%;
	align-self: right !important;
	padding: 2%;
	width: 15%;
	height: 30%;
}

.VisualizerTileDesc {
	margin-left: 1%;
	font-size: 120%;
	text-align: left;
}

@media (max-width: 500px) {
	.Main {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.VisualizerTileTitle {
		margin-left: 1%;
		font-size: 150%;
		margin-bottom: 5%;
		float: top;
		text-align: left;
		width: fit-content;
		height: fit-content;
	}

	.VisualizerTile {
		font-size: 75%;
		transition-duration: 500ms;
		width: 70%;
		height: fit-content;
		background-color: #282828;
		margin-bottom: 10%;
	}
}
