.SortingAlgorithms {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	height: fit-content;
	background-color: #282c34;
	color: white;
	min-height: 100vh;
}

.SortAlgoVis {
	flex-direction: column;
	margin: 2%;
	width: 70%;
	height: fit-content;
	background-color: #33373f00;
	display: none;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	padding: 5px;
}
#SortAlgoVis0 {
	display: flex;
}
.SortAlgoVisColumn {
	display: flex;
	flex-direction: column;
	width: 50%;
	height: 100%;
}

.SortAlgoVisTitle {
	align-self: start;
	font-size: 175%;
	margin-bottom: 1.5%;
}

.SortAlgoVisDesc {
	margin: 1%;
	font-size: 125%;
	width: 50%;
	height: 100%;
}

.SortAlgoVisGraph {
	margin: 1%;
	width: 100%;
	height: 50%;
	display: flex;
	flex-direction: row;
	align-items: end;
}

.SortingAlgorithmNavBar {
	background-color: #00000000;
	margin-top: 1%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.SortingAlgorithmNavBarButton {
	word-break: normal;
	margin-right: 0.5%;
	margin-left: 0.5%;
	background-color: #282c34;
	border: none;
	color: #fff;
	padding: 10px 20px;
	width: fit-content;
	font-size: 1vw;
	cursor: pointer;
	border-radius: 4px;
	transition: background-color 0.3s ease, transform 0.3s ease;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.SortingAlgorithmNavBarButton:hover {
	background-color: #41454e;
	transform: scale(1.1);
}

.SortingAlgorithmNavBarButton:active {
	background-color: #595d67;
	transform: scale(0.95);
}

.SortingAlgorithmNavBarButton:hover {
	background-color: #41454e;
}
.SortingAlgorithmNavBarButton:active {
	background-color: #595d67;
}

.SortAlgoVisCode {
	margin: 1%;
	font-size: 100%;
	height: fit-content;
	width: 50%;
}

.SortAlgoVisGraphBar {
	position: relative;
	/* Define the transition property */
	transition: transform 0.4s;
	text-align: center;
	font-size: 75%;
	width: 30px;
	margin: 1px;
	background-color: rgb(84, 84, 228);
}

.SortAlgoVisGraphBar.swap-start {
	transform: translateX(31px);
}

.SortAlgoVisGraphBar.swap-end {
	transform: translateX(-31px);
}

.SortAlgoVisBottomHalf {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}
.SortAlgoVisDescTop {
	font-size: 0%;
	width: 0%;
}

.SortAlgoSlider {
	width: 50%;
}
.SortAlgoSlider:hover {
	cursor: pointer;
}
.SortAlgoSlider {
	margin: 1.5%;
	width: 50%;
	height: 15px;
	-webkit-appearance: none;
	appearance: none;
	background: #333;
	outline: none;
	opacity: 0.7;
	transition: opacity 0.2s;
}

.SortAlgoSlider:hover {
	opacity: 1;
}
#DelaySliderNum {
	color: transparent;
	font-size: 0px;
}

.SortAlgoSlider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	background: #fff;
	border-radius: 50%;
	cursor: pointer;
	transition: background 0.3s ease;
}

.SortAlgoSlider::-webkit-slider-thumb:hover {
	background: #ddd;
}

.SortAlgoSlider::-webkit-slider-thumb:active {
	background: #bbb;
}

.SortAlgoSlider::-webkit-slider-runnable-track {
	width: 100%;
	height: 2px;
	cursor: pointer;
	background: #999;
}
.SortAlgoBtnContainer {
	display: flex;
	align-items: center;
}

.SortAlgoVisButton {
	background-color: #3c4049;
	border: none;
	color: #fff;
	padding: 10px 20px;
	font-size: 1.2rem;
	cursor: pointer;
	border-radius: 4px;
	transition: background-color 0.3s ease, transform 0.3s ease;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	margin: 3%;
}

.SortAlgoVisButton:hover {
	background-color: #595d67;
	transform: scale(1.1);
}

.SortAlgoVisButton:active {
	background-color: #6d717a;
	transform: scale(0.95);
}
.SortAlgoVisButton:disabled {
	background-color: #6c6c6c;
	cursor: not-allowed;
	opacity: 0.7;
	box-shadow: none;
}

.SortAlgoVisButton:disabled:hover {
	transform: none;
}

@media (max-width: 500px) {
	.SortingAlgorithmNavBarButton,
	.SortAlgoVisButton {
		padding: 5px 10px;
		font-size: 1rem;
	}
}

@media (max-width: 500px) {
	.SortAlgoSlider {
		width: 80%;
	}

	.SortAlgoSlider::-webkit-slider-thumb {
		width: 20px;
		height: 20px;
	}
	.SortAlgoVisGraphBar {
		width: 10px;
		font-size: 0px;
	}
	.SortAlgoVis {
		flex-direction: column;
		margin: 1%;
		width: 90%;
		height: fit-content;
		background-color: #33373f;
		display: none;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		padding: 2%;
	}
	.SortAlgoVisCode {
		width: 100%;
		font-size: 90%;
	}
	.SortAlgoVisDesc {
		font-size: 0%;
		width: 0%;
	}
	.SortAlgoVisDescTop {
		font-size: 100%;
		width: 100%;
		height: fit-content;
	}
	.SortAlgoVisGraphBar.swap-start {
		transform: translateX(11px);
	}

	.SortAlgoVisGraphBar.swap-end {
		transform: translateX(-11px);
	}
}
