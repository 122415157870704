span {
	text-shadow: none;
}
span.token.operator {
	background-color: transparent;
}

.Pathfinding {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow-x: hidden;
	width: 100vw;
	height: fit-content;
	min-height: 100vh;
	background-color: #282c34;
	text-align: left;
	color: white;
}
#PathFindVis0 {
	display: flex;
}
.PathFindVis {
	display: none;
	justify-content: center;
	flex-direction: column;
	margin: 1%;
	width: 80%;
	height: 70%;
	background-color: #33373f;
}

.PathFindVisColumnParent {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

.PathFindVisColumn {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	font-size: 125%;
}

.PathFindVisColumnCode {
	align-items: center;
}

.PathFindVisGrid {
	width: 85%;
	height: fit-content;
}

.PathFindVisTitle {
	font-size: 200%;
}

.PathFindVisCode {
	font-size: 70%;
	width: 99%;
	height: 100%;
	background-color: #18191b;
}

.PathFindVisCanvas {
	height: 100%;
	width: 100%;
}

#PathFindSlider {
	align-self: center;
}

.PathFindVisButtonContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}
.PathFindVisButton {
	width: 50%;
	background-color: #3c4049;
	border: none;
	color: #fff;
	padding: 5px 20px;
	font-size: 125%;
	cursor: pointer;
	border-radius: 4px;
	transition: background-color 0.3s ease, transform 0.3s ease;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	margin: 2%;
}

.PathFindVisButton:hover {
	background-color: #595d67;
	transform: scale(1.1);
}

.PathFindVisButton:active {
	background-color: #6d717a;
	transform: scale(0.95);
}
.PathFindVisButton:disabled {
	background-color: #6c6c6c;
	cursor: not-allowed;
	opacity: 0.7;
	box-shadow: none;
}

.PathFindButton:disabled:hover {
	transform: none;
}

.PathFindVisDesc {
	margin: 1%;
}

@media (max-width: 500px) {
	.PathFindVisDesc {
		font-size: 60%;
	}
	.PathFindVisTitle {
		font-size: 150%;
	}
	.PathFindVisColumnParent {
		flex-direction: column;
	}
	.PathFindVisColumn {
		width: 100%;
	}
	.PathFindVisCode {
		font-size: 50%;
		align-self: flex-end;
		width: 100%;
		height: 90%;
		background-color: #18191b;
	}
}
