.SearchAlgorithms {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	min-height: 100vh;
	height: fit-content;
	background-color: #282c34;
	color: white;
}

.SearchAlgorithmsTitle {
	font-size: 200%;
	margin-bottom: 2.5%;
	padding-top: 1.5%;
}
